'use client'
import { Canvas } from "@react-three/fiber"
import { OrbitControls, Stats } from "@react-three/drei"
import Wave from './wave'


export default function R3FBox() {
  return (
    <div className="w-1/2 h-[20vh] ">
      <Canvas orthographic camera={{ near: 0.01, far: 1000, zoom: 10, position: [4, 6, 20] }}
        className='bg-[#f8f5f2] dark:bg-[#111010] transition-all duration-500' >
        {/* className='bg-slate-800' > */}

        {/* geometry */}
        <Wave />

        {/* light */}
        {/* <ambientLight />
        <directionalLight color="white" position={[5, 5, 5]} /> */}

        {/* helper tools */}
        {/* <OrbitControls />
        <axesHelper args={[100]} />
        <gridHelper args={[50]} />
        <Stats /> */}
      </Canvas>
    </div >
  )
}
