import { useRef, useState, useMemo } from "react";
import { useTheme } from "next-themes";
import { BufferAttribute, Mesh } from "three";
import { useFrame } from "@react-three/fiber"


function BufferPoints({ count = 8000 }) {
  const { theme } = useTheme()

  const pointsRef = useRef<any>();
  const points = useMemo(() => {
    const vertices = new Float32Array(count * 3);
    for (let i = 0; i < count; i++) {
      vertices[i] = Math.random() * 10;
    }
    return new BufferAttribute(vertices, 3);
  }, [count]);

  // useFrame 钩子用于每一帧的动画更新
  useFrame((state) => {
    const positions = pointsRef.current?.geometry?.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
      // 在垂直水平上先后以Math.sin方式上下移动
      const t = state.clock.getElapsedTime();
      positions[i + 1] = Math.sin(t + positions[i] * 1.5) / 2;
      // positions[i + 2] = Math.sin(t + positions[i + 1] * 2) / 2;
    }
    // 更新缓冲区
    pointsRef.current.geometry.attributes.position.needsUpdate = true;
  });


  return (
    <points ref={pointsRef} scale={[4, 4, 4]} position={[-16, 0, 0]}>
      <bufferGeometry>
        <bufferAttribute attach={"attributes-position"} {...points} />
      </bufferGeometry>
      <pointsMaterial
        size={0.8}
        color={theme == 'light' ? 0x000000 : 0xffffff}
        sizeAttenuation={true}
      />
    </points>
  );
}


export default function Wave() {
  const [hovered, setHovered] = useState(false)
  return (
    <BufferPoints count={22000} />
  )
}
// // 粒子生成
// const count = 1000000;
// const vertices = new Float32Array(count * 3);

// for (let i = 0; i < count; i++) {
//   vertices[i] = Math.random() * 10;
// }

// const geometry = new THREE.BufferGeometry();
// geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

// const material = new THREE.PointsMaterial({
//   size: 0.1,
//   sizeAttenuation: true,
//   color: "black",
// });

// const particles = new THREE.Points(geometry, material);
// scene.add(particles);
